:root {
    --bg: rgb(77, 75, 78);
    --buttonbg: rgb(206, 201, 207);
    --navbarbg: rgb(164, 0, 64);
  }

html, body {
    margin: 0;
    padding: 0;
    width: 99%;
    height: 100%;
    background: var(--bg);
    

  }
header {
    background: rgb(164, 0, 64);
    top: 0;
    left: 0;
    width: 100%;
    height: 7vh;
    padding: 1vh 2vh; 
}
main {
    background: var(--bg);
    height: calc(92vh-20px);
    width: 100%;
    padding-left: 2vh;
    padding-right:1vh;
    color: var(--buttonbg);
    justify-content: center;
    
}

iframe {
    width: 100%;
    height:100%;
    border:none;
    overflow: hidden;
    display: block;
}
.center{
    display: block;
    margin: auto;
    max-width:80%;
    height: auto;
}
.logo {
    max-width: 20%;
    height:auto;
    margin-right: auto;
}
.rotate-90{
    transform: rotate(-90deg);
}
.pdf{
    height: 100vh;
    box-sizing: border-box;
}
nav{
    transition: text-decoration 1s, background-color 1s;
    transition: top 0.3s;
    display:flex;
    align-items:center;
    padding-left:3vh;
}
.nav-list{
    margin: 0;
    margin-right: 3vh;
    list-style:none;
    font-size: 1rem;
}
.nav-list li{
    background: var(--navbarbg);
    margin-left: 20px;
    display: inline-block;
    border-radius: 100vw 100vw 100vw 100vw;
    border: 2px solid white;
}
.nav-list li.active {
    background: var(--buttonbg);
}
.nav-list li.active a {
    text-decoration-color: var(--buttonbg);
    color: var(--navbarbg);   
}
.nav-list a{
    display: inline-block;
    color: white;
    text-decoration:underline var(--navbarbg);
    text-align: center;
    border-radius: 100vw 100vw 100vw 100vw;
    transition: text-decoration-color 1s, background-color 1s;
    padding: 0.3rem 0.75rem;
}
.nav-list a:hover{
    color: var(--navbarbg);
    text-decoration-color: var(--navbarbg);
    background-color: var(--buttonbg);
}

.fraction {
    position: relative;
    display: inline-block;
    vertical-align: middle; 
  }
  .numerator {
    border-bottom: 2px solid black;
    text-align: center;
  }
  .denominator {
    text-align: center;
  }

.container{
    display:flex;
    flex-direction: row;
    align-items: center;
    overflow:hidden;
    height:90vh;
}
  
.left-side{
    flex: 1; 
    position: sticky;
    height: 90vh;
}
.right-side{
    flex: 1;
    padding-left:2vh;
    overflow-y: auto;
    height:90vh;
}
.homepage-row{
    display:flex;
    flex-direction: row;
    align-items: center;
    overflow:hidden;
    height:75vh;
}
.intro-left{
    flex: 1; 
    max-width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding:5vh;
    overflow: hidden;
}
.intro-left img {
    max-width: 100%; 
    height: auto;
    object-fit: contain;
}
.intro-right{
    flex: 1; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column; 
    padding:5vh;
}
.intro-right img {
    max-width: 100%; 
    height: auto;
    object-fit: contain;
    display:block;
}

.project-item {
    flex: 1; 
    max-width: 30%;
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: center;
    padding:5vh;
    overflow: hidden;
}
.project-item img {
    max-width: 100%; 
    height: 40%;
    object-fit: contain;
    display:block;
}
.project-item desc {
    display:block;
}


